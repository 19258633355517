<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: moduleId == 5 ? $route.matched[3].meta.routeLeadPending : $route.matched[2].meta.routeLeadPending }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >PENDING</b-nav-item
      >
      <b-nav-item
        :to="{ name: moduleId == 5 ? $route.matched[3].meta.routeLeadFinished : $route.matched[2].meta.routeLeadFinished }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >FINISHED</b-nav-item
      >
      <b-nav-item
        :to="{ name: moduleId == 5 ? $route.matched[3].meta.routeLeadAnnulled : $route.matched[2].meta.routeLeadAnnulled }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >ANNULLED</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <RequestNcrTable />
    </b-card>
  </div>
</template>
  <script>
import RequestNcrTable from "@/views/commons/components/request-ncr/views/components/RequestNcrTable.vue";

export default {
  components: {
    RequestNcrTable,
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {},
};
</script>
